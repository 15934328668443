<template>
  <BOverlay
    :show="isProcessing"
    opacity="0.8"
    variant="transparent"
  >
    <div class="d-lg-flex align-items-start">
      <BindEquipmentModal
        v-if="checklistData.id"
        :checklist-id="checklistData.id"
        @updateData="getChecklistById"
      />
      <div class="col-12 col-lg-4">
        <b-card>
          <div>
            <div
              class="d-flex align-items-start justify-content-between"
              :class="{'mb-1' : !newChecklistStatus}"
            >
              <EditInput
                :model="checklistData.name"
                :toggle-only-by-icon="false"
                :readonly="!$store.getters['user/permission']('check_lists:update')"
                @input="updateField('name', $event)"
              >
                <h4
                  class="mb-0 cursor-pointer editable-field"
                  :class="{'text-muted': !checklistData.name}"
                >
                  {{ checklistData.name || 'Название чек-листа' }}
                </h4>
              </EditInput>
              <b-modal
                id="checklist-delete-modal"
                cancel-variant="outline-secondary"
                ok-variant="danger"
                centered
                title="Удаление чек-листа"
                size="sm"
                ok-title="Удалить"
                cancel-title="Отмена"
                @ok="deleteChecklist"
              >
                <h5>Вы действительно хотите удалить чек-лист {{ checklistData.name }} ?</h5>
              </b-modal>
              <div v-if="newChecklistStatus">
                <b-dropdown
                  v-if="$store.getters['user/permission']('check_lists:delete')"
                  variant="white"
                  toggle-class="text-decoration-none relative p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      class="text-body mx-auto font-weight-bolder"
                      icon="MoreVerticalIcon"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$store.getters['user/permission']('check_lists:delete')"
                    v-b-modal="`checklist-delete-modal`"
                    class="border-0 w-100"
                    @click.prevent
                  >
                    <feather-icon
                        class="text-danger font-weight-bolder mr-1"
                        icon="Trash2Icon"
                    />
                    <span class="text-danger">
                      {{ t('settings.delete') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <tags
              v-if="checklistData && checklistData.tags"
              :entity="checklistData"
              entity-name="check_lists"
              @input="tagsUpdated"
            />
            <div
              class="d-flex"
              :class="{'mb-1' : !newChecklistStatus}"
            >
              <EditInput
                :model="checklistData.description"
                :toggle-only-by-icon="false"
                :readonly="!$store.getters['user/permission']('check_lists:update')"
                @input="updateField('description', $event)"
              >
                <h5
                  class="mb-0 cursor-pointer editable-field"
                  :class="{'text-muted': !checklistData.description}"
                >
                  {{ checklistData.description || 'Описание чек-листа' }}
                </h5>
              </EditInput>
            </div>
            <b-form-checkbox
              class="mt-1"
              name="check-button"
              switch
              inline
              :checked="!!checklistData.is_stepper"
              :disabled="!$store.getters['user/permission']('check_lists:update')"
              @change="updateField('is_stepper', $event ? 1 : 0)"
            >
              <div
                class="d-flex align-items-start"
              >
                <span style="margin-right: 0.4rem">Заполнять пошагово</span>
                <FeatherIcon
                  v-b-popover.hover.top="
                    'Сотруднику нужно будет ' +
                      'заполнять чек-лист, ' +
                      'отвечая на вопросы строго последовательно. ' +
                      'Как пошаговый квиз.'"
                  icon="HelpCircleIcon"
                  size="12"
                />
              </div>
            </b-form-checkbox>
            <div
              v-if="newChecklistStatus"
              class="mt-1 font-weight-bold"
            >
              <span style="text-decoration: underline">Привязано к оборудованию</span>
              <span> - {{ checklistData.equipments_count }}</span>
            </div>
            <div
              v-if="newChecklistStatus && $store.getters['user/permission']('check_lists:update')"
              v-b-modal="`bindEquipment`"
              class="d-flex text-primary align-items-center mt-1 cursor-pointer"
            >
              <FeatherIcon
                class="cursor-pointer"
                icon="PaperclipIcon"
              />
              <p
                class="mb-0"
                style="margin-left: 0.5rem"
              >
                Прикрепить к оборудованию
              </p>
            </div>
          </div>
        </b-card>
        <div
          v-if="!newChecklistStatus"
          class="d-flex justify-content-end my-2"
        >
          <b-button
            class="mr-2"
            variant="outline-primary"
            :to="{name: 'checklists'}"
          >
            Отменить
          </b-button>
          <b-button
            variant="primary"
            :disabled="!checklistData.name"
            @click="addNewChecklist"
          >
            Сохранить
          </b-button>
        </div>
      </div>
      <div
        v-if="newChecklistStatus"
        class="col-12 col-lg-8"
      >
        <component
          :is="$store.getters['user/permission']('check_lists:update') ? 'Draggable' : 'div'"
          v-model="checklistData.checklist_items"
          animation="200"
          item-key="id"
          @change="changePosition"
        >
          <div
            v-for="(item, index) in checklistData.checklist_items"
            :key="item.id"
          >
            <b-card
              v-if="itemId !== item.id"
              class="cursor-pointer mb-1"
              @click.stop="editItem(item)"
            >
              <div class="d-flex justify-content-between align-items-start">
                <div class="d-flex">
                  <div
                    v-if="$store.getters['user/permission']('check_lists:update')"
                    class="d-flex align-items-center text-muted mr-1"
                  >
                    <span style="margin-right: -0.8rem;">
                      <FeatherIcon
                        class="cursor-pointer m-0 p-0"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </span>
                    <span>
                      <FeatherIcon
                        class="cursor-pointer"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </span>
                  </div>
                  <h4 class="m-0">
                    {{ item.name }}
                  </h4>
                </div>
                <div>
                  <b-dropdown
                    v-if="$store.getters['user/permission']('check_lists:update') ||
                      $store.getters['user/permission']('check_lists:delete')"
                    variant="white"
                    toggle-class="text-decoration-none relative p-0"
                    right
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        class="text-body mx-auto font-weight-bolder"
                        icon="MoreVerticalIcon"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="$store.getters['user/permission']('check_lists:update')"
                      class="border-0 w-100"
                      @click.stop="duplicateItem(item)"
                    >
                      <div class="d-flex justify-content-start">
                        <feather-icon
                          class="font-weight-bolder mr-1"
                          icon="CopyIcon"
                        />Дублировать
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="$store.getters['user/permission']('check_lists:delete')"
                      class="border-0 w-100"
                      @click.stop="deleteItem(item.id, index)"
                    >
                      <div class="d-flex justify-content-start">
                        <feather-icon
                          class="font-weight-bolder mr-1"
                          icon="Trash2Icon"
                        />Удалить
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-card>
            <ChecklistQuestionsCard
              v-if="itemId === item.id && !questionStatus"
              :item="item"
              @updateData="updateItem($event, index)"
              @cancel="closeItemForm"
            />
          </div>
        </component>
        <ChecklistQuestionsCard
          v-if="questionStatus"
          @updateData="updateItem"
          @cancel="closeItemForm"
        />
        <b-button
          v-if="!isProcessing && (itemId || !questionStatus) &&
            $store.getters['user/permission']('check_lists:update')"
          variant="outline-primary"
          class="mb-2"
          @click="openItemForm"
        >
          + Добавить пункт
        </b-button>
      </div>
    </div>
  </BOverlay>
</template>

<script>
import {
  BCard,
  BButton,
  BFormCheckbox,
  BDropdownItem,
  BDropdown,
  BModal,
  VBPopover,
  VBModal,
  BOverlay,
} from 'bootstrap-vue';
import {
  ref,
} from '@vue/composition-api';
import Draggable from 'vuedraggable';
import {
  addChecklist,
  updateChecklist,
  checklistDelete,
  addChecklistItem,
  getChecklist,
  changeItemPosition,
  duplicateChecklistItem,
  deleteChecklistItem,
  updateChecklistItem,
} from '@/store/checklists/checklists.api';
import EditInput from '@/components/editInput/EditInput.vue';
import BindEquipmentModal from '@/components/checklist/BindEquipmentModal.vue';
import ChecklistQuestionsCard from '@/components/checklist/ChecklistQuestionsCard.vue';
import { useRouter } from '@/hooks/useRouter';
import { useToast } from '@/hooks/useToast';
import Tags from '@/components/tags/Tags.vue';
import {useStore} from "@/hooks/useStore";
import {useI18n} from "@/hooks/useI18n";

export default {
  name: 'ChecklistAdd',
  components: {
    BCard,
    BFormCheckbox,
    BModal,
    BButton,
    BDropdownItem,
    BDropdown,
    EditInput,
    ChecklistQuestionsCard,
    Draggable,
    BindEquipmentModal,
    BOverlay,
    Tags,
  },
  directives: {
    'b-popover': VBPopover,
    'b-modal': VBModal,
  },
  props: {
    checklistId: {
      type: [Number, String],
      default: null,
    },
  },

  setup(props) {
    const checklistData = ref({
      name: '',
      description: null,
      is_stepper: 0,
      checklist_items: [],
    });

    const { t } = useI18n();

    const itemId = ref(null);

    const { router } = useRouter();

    const loading = ref(false);
    const isProcessing = ref(false);
    const newChecklistStatus = ref(false);
    const checklistItem = ref(null);
    const questionStatus = ref(false);
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    const { successToast, dangerToast } = useToast();

    const getChecklistById = () => {
      isProcessing.value = true;
      getChecklist(props.checklistId || checklistData.value).then((res) => {
        checklistData.value = res.data.response;
        !checklistData.value.checklist_items?.length
          ? questionStatus.value = true : questionStatus.value = false;
      }).finally(() => {
        isProcessing.value = false;
      });
    };

    if (props.checklistId) {
      getChecklistById();
      newChecklistStatus.value = true;
    }

    const addNewChecklist = () => {
      loading.value = true;
      isProcessing.value = true;
      addChecklist(checklistData.value).then((res) => {
        checklistData.value = res.data.response;
        !checklistData.value.checklist_items?.length
          ? questionStatus.value = true : questionStatus.value = false;
        newChecklistStatus.value = true;
        router.push({ name: 'checklist-view', params: { checklistId: checklistData.value.id } });
      }).finally(() => {
        isProcessing.value = false;
        loading.value = false;
      });
    };

    const deleteChecklist = () => {
      checklistDelete(checklistData.value.id).then(() => {
        router.push({ name: 'checklists' });
      });
    };

    const updateField = (field, value) => {
      checklistData.value[field] = value;
      if (newChecklistStatus.value) {
        updateChecklist(checklistData.value.id, {
          name: checklistData.value.name,
          description: checklistData.value.description,
          is_stepper: checklistData.value.is_stepper,
        })
          .then(() => {
            successToast('Успешно обновлено');
          })
          .catch(() => {
            if (!checklistData.value.name) {
              dangerToast(('Поля "Название чек-листа" не может быть пустым'));
              getChecklistById();
            }
          });
      }
    };

    const editItem = (item) => {
      itemId.value = item.id;
      questionStatus.value = false;
    };

    const deleteItem = (id, index) => {
      deleteChecklistItem(id).then(() => {
        checklistData.value.checklist_items.splice(index, 1);
        itemId.value = null;
        questionStatus.value = false;
      });
    };

    const updateItem = (item, index) => {
      if (!itemId.value) {
        addChecklistItem(checklistData.value.id, item).then((res) => {
          checklistData.value.checklist_items.push(res.data.response);
          questionStatus.value = false;
          itemId.value = null;
          successToast('Успешно добавлено');
        });
        return;
      }

      if (['text', 'number', 'switch', 'date', 'file'].includes(item.answer_type)) {
        // eslint-disable-next-line no-param-reassign
        item.answer_options = null;
      }
      updateChecklistItem(item.id, item).then(() => {
        checklistData.value.checklist_items[index] = item;
        successToast('Успешно обновлено');
        questionStatus.value = false;
        itemId.value = null;
      });
    };

    const duplicateItem = (item) => {
      duplicateChecklistItem(item.id, item).then((res) => {
        checklistData.value.checklist_items.push(res.data.response);
      });
    };

    const changePosition = () => {
      const position = checklistData.value.checklist_items.map((item) => item.id);
      const formData = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const item of position) {
        formData.append('position[]', item);
      }
      changeItemPosition(formData);
    };

    const openItemForm = () => {
      questionStatus.value = true;
      itemId.value = null;
    };

    const closeItemForm = () => {
      questionStatus.value = false;
      itemId.value = null;
    };

    const tagsUpdated = (returnedTags) => {
      if (returnedTags) checklistData.value.tags = returnedTags;
    };

    return {
      isProcessing,
      closeItemForm,
      openItemForm,
      getChecklistById,
      itemId,
      editItem,
      changePosition,
      questionStatus,
      checklistItem,
      deleteChecklist,
      updateItem,
      duplicateItem,
      deleteItem,
      checklistData,
      newChecklistStatus,
      addNewChecklist,
      updateField,
      tagsUpdated,
      isMobile,
      t,
    };
  },
};
</script>
