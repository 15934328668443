<template>
  <div>
    <BCard>
      <div class="d-md-flex">
        <div class="col-12 col-md-7 mb-1">
          <b-form-group
            label="Название пункта"
            label-for="nameInput"
          >
            <b-form-input
              id="nameInput"
              v-model="checklistItemData.name"
              placeholder="Введите название"
              :disabled="!$store.getters['user/permission']('check_lists:update')"
            />
          </b-form-group>
          <b-form-group
            label="Описание"
            label-for="questionDescription"
          >
            <quill-editor
              id="questionDescription"
              v-model="checklistItemData.description"
              :options="editorOption"
              placeholder="Введите описание"
              :disabled="!$store.getters['user/permission']('check_lists:update')"
            />
          </b-form-group>
          <div>
            <b-form-checkbox
              class="mt-1 d-block"
              name="check-button"
              switch
              inline
              :checked="!!checklistItemData.can_not_skip"
              :disabled="!$store.getters['user/permission']('check_lists:update')"
              @change="checklistItemData.can_not_skip = $event ? 1 : 0"
            >
              <div
                class="d-flex align-items-start"
              >
                <span style="margin-right: 0.4rem">Обязательное поле</span>
                <FeatherIcon
                  v-b-popover.hover.top="
                    'Сотрудник не сможет завершить обслуживание, ' +
                      'не ответив на все обязательные вопросы.'"
                  icon="HelpCircleIcon"
                  size="12"
                />
              </div>
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-12 col-md-5 mr-0">
          <b-form-group
            label="Ответ сотрудника"
            label-for="employeeAnswer"
          >
            <v-select
              id="employeeAnswer"
              v-model="checklistItemData.answer_type"
              placeholder="Тип поля"
              :reduce="(answer_type) => answer_type.type"
              label="title"
              :options="answerOption"
              :disabled="!$store.getters['user/permission']('check_lists:update')"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
          </b-form-group>
          <div
            v-if="checklistItemData.answer_type === 'checkbox'
              || checklistItemData.answer_type === 'radio'"
          >
            <label>Варианты ответа</label>
            <div
              v-for="(item, index) in array"
              :key="item + index"
              class="d-flex align-items-center mb-1"
            >
              <b-form-input
                :value="array[index]"
                :placeholder="'вариант ' + (index + 1)"
                :disabled="!$store.getters['user/permission']('check_lists:update')"
                @input="changeArray($event, index)"
              />
              <FeatherIcon
                class="cursor-pointer text-danger ml-1"
                icon="XIcon"
                size="25"
                @click="removeAnswer(index)"
              />
            </div>
            <p
              class="text-primary cursor-pointer"
              @click="pushElement"
            >
              Добавить вариант
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-end w-100">
        <div
          v-if="$store.getters['user/permission']('check_lists:update')"
          class="d-flex justify-content-end mt-2"
        >
          <b-button
            class="mr-2"
            variant="outline-primary"
            @click="cancel"
          >
            Отмена
          </b-button>
          <b-button
            variant="primary"
            :disabled="!checklistItemData.name || !checklistItemData.answer_type"
            @click="saveData"
          >
            Сохранить
          </b-button>
        </div>
      </div>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  VBPopover,
} from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { nextTick, ref } from '@vue/composition-api';

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  name: 'ChecklistQuestionsCard',
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    quillEditor,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const editorOption = ref(
      {
        modules: {
          toolbar: [
            ['bold', 'italic'],
            ['link', 'blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        },
        theme: 'snow',
        placeholder: 'Введите описание',
      },
    );

    const array = ref(['']);

    const checklistItemData = ref({
      name: '',
      description: '',
      can_not_skip: 0,
      answer_type: 'switch',
      answer_options: '',
    });

    if (props.item) {
      checklistItemData.value = { ...props.item };
      if (props.item.answer_options?.length)array.value = props.item.answer_options;
    }
    const answerOption = ref([
      {
        title: 'Флаг',
        type: 'switch',
        icon: 'ToggleLeftIcon',
      },
      {
        title: 'Выбрать вариант',
        type: 'radio',
        icon: 'DiscIcon',
      },
      {
        title: 'Выбрать несколько',
        type: 'checkbox',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Текст ',
        type: 'text',
        icon: 'TypeIcon',
      },
      {
        title: 'Числовое значение',
        type: 'number',
        icon: 'HashIcon',
      },
      {
        title: 'Дата ',
        type: 'date',
        icon: 'CalendarIcon',
      },
      {
        title: 'Фото и видео ',
        type: 'file',
        icon: 'ImageIcon',
      },
    ]);

    const cancel = () => {
      emit('cancel');
    };

    const saveData = () => {
      const checklistDataCopy = { ...checklistItemData.value };
      if (array.value[0]?.length)checklistDataCopy.answer_options = array.value;

      emit('updateData', checklistDataCopy);
    };

    const pushElement = () => {
      array.value.push('');
    };

    const changeArray = (event, index) => {
      array.value[index] = event;
    };

    const removeAnswer = (index) => {
      if (array.value.length > 1) {
        array.value.splice(index, 1);
        return;
      }
      array.value.splice(index, 1);
      nextTick(() => { array.value.push(''); });
    };

    return {
      changeArray,
      removeAnswer,
      saveData,
      cancel,
      array,
      pushElement,
      checklistItemData,
      answerOption,
      editorOption,
    };
  },
};
</script>
