<template>
  <b-modal
    id="bindEquipment"
    title="Изменить оборудование"
    centered
    size="xl"
    body-class="px-0"
    ok-title="Сохранить"
    cancel-title="Отменить"
    cancel-variant="outline-primary"
    @ok="saveData"
  >
    <EquipmentsTable
        :custom-per-page="5"
        class="link-equipments-table"
        no-export
        no-tabs
        no-creating
        no-cache
        link
        :selected.sync="equipmentIds"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue';
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api';
import {
  getEquipments,
  getSpareparts,
} from '@/store/equipments/equipments.api';
import { updateChecklist, getChecklistEquipments } from '@/store/checklists/checklists.api';
import {useStore} from "@/hooks/useStore";
import EquipmentsTable from "@/components/entityTables/EquipmentsTable.vue";
import {useI18n} from "@/hooks/useI18n";
import {useToast} from "@/hooks/useToast";

export default {
  name: 'BindEquipmentModal',
  components: {
    EquipmentsTable,
    BModal,
  },
  props: {
    checklistId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const tabIndex = ref(0);
    const usersTable = ref(null);
    const isMobile = ref(false);
    const store = useStore();
    const { t } = useI18n();
    const { successToast } = useToast();

    isMobile.value = store.state.app.isMobile;

    const tableColumns = [
      {
        key: 'title', label: 'Название', sortable: true,
      },
      { key: 'article', label: 'Артикул', sortable: false },
      { key: 'manufacturer', label: 'Производитель', sortable: true },
    ];

    const equipments = ref([]);
    const equipmentsTotal = ref(0);

    const pagination = ref({
      page: 1,
      perPage: 10,
    });
    const total = computed(() => Math.ceil(equipmentsTotal.value / pagination.value.perPage));
    const perPageOptions = [10, 25, 50, 100];

    const searchQuery = ref('');
    const sortBy = ref('id');
    const sortDesc = ref(false);
    const equipmentIds = ref([]);

    const getEquipmentsIds = () => {
      getChecklistEquipments(props.checklistId, { per_page: 1000 }).then((res) => {
        equipmentIds.value = res.data.response.data.map((item) => item.id);
        for (let i = 0; i <= res.data.response.data.length; i += 1) {
          if (res.data.response.data[i]?.equipments?.length) {
            for (let j = 0; j <= res.data.response.data.equipments?.length; j += 1) {
              equipmentIds.value.push(res.data.response.data[i]?.equipments[j]?.id);
            }
          }
        }
      });
    };
    getEquipmentsIds();

    const isProcessing = ref(false);
    const getAllEquipments = () => {
      isProcessing.value = true;
      (tabIndex.value ? getSpareparts({
        sortBy: sortBy.value,
        q: searchQuery.value,
        ...pagination.value,
      }) : getEquipments({
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        q: searchQuery.value,
        ...pagination.value,
      })).then((res) => {
        equipments.value = res.data.response.data;
        pagination.value.perPage = res.data.response.per_page;
        equipmentsTotal.value = res.data.response.total;
      })
        .finally(() => { isProcessing.value = false; });
    };

    const clickedRow = ref(null);

    onMounted(() => {
      getAllEquipments();
    });

    watch([pagination, sortBy, sortDesc, tabIndex], () => getAllEquipments(), { deep: true });

    watch(searchQuery, () => {
      getAllEquipments();
    });

    const onRowClicked = (equipment) => {
      if (!tabIndex.value) {
        clickedRow.value !== equipment.id ? clickedRow.value = equipment.id
          : clickedRow.value = null;
      }
    };

    const changeSortBy = (sort) => {
      sort !== sortBy.value ? sortBy.value = sort : sortDesc.value = !sortDesc.value;
    };

    const changeBind = (event, equipmentId) => {
      if (event) {
        equipmentIds.value.push(equipmentId);
      } else {
        equipmentIds.value = equipmentIds.value?.filter((item) => item !== equipmentId);
      }
    };

    const saveData = () => {
      updateChecklist(props.checklistId, { equipment_id: equipmentIds.value });
      successToast(t('checklists.message.equipment_change'));
      getAllEquipments();
      emit('updateData');
    };

    return {
      saveData,
      changeBind,
      clickedRow,
      changeSortBy,
      tabIndex,
      usersTable,
      tableColumns,
      getAllEquipments,
      equipments,
      equipmentsTotal,
      pagination,
      total,
      perPageOptions,
      searchQuery,
      sortBy,
      sortDesc,
      isProcessing,
      onRowClicked,
      isMobile,
      equipmentIds,
    };
  },
};
</script>
